import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadDefaultPermissionsUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { DefaultPermission, DefaultPermissionResponse } from '../types';

export const loadDefaultPermissions = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: DefaultPermission[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const url = buildLoadDefaultPermissionsUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<DefaultPermissionResponse>) => {
            const { DefaultPermissions } = response;
            const result = convertToCamel<DefaultPermission[]>(DefaultPermissions);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
